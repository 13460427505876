<template>
  <nav :class="[isTransparent ? 'nav-transparent' : 'nav-solid']">
    <DisplayContainer>
      <div class="navbar">
        <div class="nav-section">
          <NuxtLink to="/" class="nav-btn-home">
            <img src="/img/logo-nav.png" alt="Logo" class="nav-logo" />
            <p>Ansearch</p>
          </NuxtLink>
          <!-- <NuxtLink to="/features" class="nav-btn display-desktop">
              Features
            </NuxtLink> -->
          <NuxtLink to="/security" class="nav-btn display-desktop">
            Security
          </NuxtLink>
          <NuxtLink to="/about" class="nav-btn display-desktop">
            About
          </NuxtLink>
          <NuxtLink to="/contact" class="nav-btn display-desktop">
            Contact
          </NuxtLink>
          <NuxtLink to="/blog" class="nav-btn display-desktop"> Blog </NuxtLink>
          <!-- <NuxtLink to="/plans" class="nav-btn display-desktop"> Plans </NuxtLink> -->
        </div>

        <div class="nav-section nav-section-left">
          <BtnCallToAction class="nav-btn-alt display-desktop" />
          <BtnCallToActionAlt class="nav-btn-alt display-desktop" />

          <button
            class="nav-btn-menu display-mobile"
            @click="$refs.mobileMenu.toggleMobileMenu"
          >
            <Icon name="fa6-solid:bars" />
            <div>Menu</div>
          </button>
        </div>
      </div>
      <NavMenuMobile ref="mobileMenu" />
    </DisplayContainer>
  </nav>
</template>

<script setup lang="ts">
const route = useRoute();
const state = reactive({
  scrollY: 0,
});

/**
 * Link the scroll event to handleScroll
 * note: there is no alternative due to the specifics of Nuxt SSR
 * (see for more details: https://nuxtjs.org/docs/concepts/server-side-rendering)
 */
onBeforeMount(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

/**
 * Link the windows scroll state to the reactive variable
 */
function handleScroll() {
  state.scrollY = window.scrollY;
}

/**
 * Verify if the navbar should be transparent or solid
 */
const isTransparent = computed(() => {
  // if this is not the top of the page, make a solid nav
  if (state.scrollY > 0) {
    return false;
  }
  // else make the navbar transparent
  return true;
});
</script>

<style scoped>
nav {
  position: fixed;
  width: 100%;
  /* this Z-index takes into account:
    - The bubble animation, and the hero content (Z-0 and z-1)
  */
  z-index: 2;
  background-color: transparent;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.nav-solid {
  color: var(--text-foreground);
  background-color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid var(--seperator-colour);
}

.navbar {
  display: flex;
  justify-content: space-between;
}

.nav-logo {
  height: 2rem;
  width: auto;
}

.nav-section {
  display: flex;
}

.nav-section-left {
  justify-content: flex-end;
}

.nav-btn {
  display: flex;
  align-items: center;
  padding: 1rem 1.2rem;
  min-height: 40px;
  color: inherit;
  gap: 0.25rem;
}

.nav-btn:hover {
  color: var(--branding-primary-colour);
}

.nav-btn-home {
  display: flex;
  align-items: center;
  padding: 1rem 1.2rem;
  min-height: 40px;
  color: inherit;
  gap: 0.25rem;
}

.nav-btn-home h1 {
  font-size: 1rem;
  font-weight: 500;
}

.nav-btn-alt {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin: 1rem 0.5rem;
}

.nav-btn-menu {
  padding: 1rem 1.2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: inherit;
  background-color: inherit;
  cursor: pointer;
}

.nav-btn-menu > div {
  font-size: smaller;
}
</style>
